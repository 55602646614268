import { useLayoutEffect } from "react";
import "../styling/SpaPaket.css";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";

export function SpaPackage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const isMobile = useMediaQuery({ maxWidth: "1550px" });

  return (
    <>
      <div className="hero-div-spa">
        <div className="hero-text">
          <h1>Spapaket</h1>
        </div>
      </div>
      <Helmet>
        <title>Spapaket</title>
        <meta
          name="description"
          content="Boka våra härliga Spa-paket med eller utan övernattning. Alltid bara ett sällskap i taget, och ni har hela Spa Ladan för er själva, med mat, dryck, Spa-bad och Bastu."
        />
      </Helmet>
      <div className="spapaket-wrapper">
        <div className="intro-spapaket">
          <div className="left">
            <div className="top-left">
              <h2>Spapaket för 2-8 personer</h2>
              <p>
                Varmt välkomna att boka in er på vårt nyöppnade mini spa. Vi har
                7 bäddar för våra övernattande gäster, se våra olika paket och
                boka direkt på hemsidan. Här kan man bada Spabad, bada bastu och
                samtidigt njuta av den vackra naturen. Den goda maten är
                hemlagad och ändras efter säsong.
              </p>
              <p>
                För extra lyx kan ni lägga till behandlingar som massage,
                ansiktsbehandlingar fotvård etc.
              </p>
              <p>
                Behandlingar i samband med Spapaket bokas separat via mail
                annelie@spaladan.se
              </p>
            </div>
            <div className="paketen">
              <div className="bg-dark">
                <h2>Dag och kvällspa</h2>
                <p className="italic">Pris per person <br></br>
              I alla paket ingår mat och dessert</p>
                <table className="table-fixed border-spacing-y-px">
                  <thead>
                    <tr>
                      <th>Spapaket</th>
                      <th>7-8p</th>
                      <th>5-6p</th>
                      <th>3-4p</th>
                      <th>2p</th>
                      <th>Frukt & Ostbricka</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-y-2 px-1 hover:bg-neutral-100">
                      <td>4 timmar</td>
                      <td>750:-</td>
                      <td>850:-</td>
                      <td>950:-</td>
                      <td>1025:-</td>
                      <td>Ingår ej</td>
                    </tr>
                    <tr>
                      <td>6 timmar</td>
                      <td>950:-</td>
                      <td>1050:-</td>
                      <td>1150:-</td>
                      <td>x</td>
                      <td>Ingår</td>
                    </tr>
                    <tr>
                      <td>8 timmar</td>
                      <td>1050:-</td>
                      <td>1150:-</td>
                      <td>x</td>
                      <td>x</td>
                      <td>Ingår</td>
                    </tr>
                  </tbody>
                </table>
               
              </div>
              <div className="bg-dark">
                <h2>Spapaket med övernattning</h2>
                <p>
                  Vi erbjuder ett dubbelrum och ett trebäddsrum, och två bäddar
                  i vårt sällskapsrum på övervåningen.
                </p>
                <p>Både dubbelrum och trebäddsrum har luftkonditionering</p>
                <p className="italic">Pris per person <br></br>
                I alla paket ingår mat och dessert och frukost</p>
                <table className="table-fixed border-spacing-y-px w-full place-content-space-around">
                  <thead>
                    <tr>
                      <th>5-7 st</th>
                      <th>3-4 st</th>
                      <th>2 st</th>
                      <th>Frukt & Ostbricka</th>
                      <th>Frukost</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-y-2 px-1 hover:bg-neutral-100">
                      <td>1450:-</td>
                      <td>1650:-</td>
                      <td>1795:-</td>
                      <td>Ingår</td>
                      <td>Ingår</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {isMobile && (
            <div className="spapaket-innehåll">
              <div>
                <h2>I alla paket ingår:</h2>
                <ul className="innehållslista">
                  <li>
                    Mat: Vår och sommar bjuder vi på örtmarinerad kycklingfile,
                    färskpotatissallad med soltorkade tomater. <br></br>Höst och
                    vinter serveras en värmande soppa goda tillbehör.
                  </li>
                  <li>Kaffe/Te och dessert.</li>
                  <li>Lån av badrock och handduk</li>
                  <li>Schampo och balsam</li>
                </ul>
                <p>
                  Till maten finns dryck att köpa, välj med eller utan alkohol.
                </p>
                <ul className="innehållslista">
                  <li>bubbel</li>
                  <li>rött och vitt vin</li>
                  <li>öl</li>
                  <li>läsk och mineralvatten</li>
                </ul>
                <p>
                  I vår snackbar finns även lite annat tilltugg som nötter,
                  choklad, godis med mera.
                </p>
              </div>

              <img
                className="matbild"
                src={"/assets/sommarmat.jpg"}
                alt="matbild"
              ></img>
            </div>
          )}
          <div className="right">
            <div className="bg-dark-round">
              <p>Kom och njut en hel dag av allt Spa-ladan har att erbjuda.</p>
              <p>
                Det finns möjlighet för alla att boka till behandlingar från
                behandlingsmenyn under vistelsetiden
              </p>
              <p>
                Bokningsbart lördagar 10,00-22,00 (även andra dagar möjliga –
                skicka en förfrågan)
              </p>
            </div>
            <img src={"/assets/IMG_3798.JPG"} width={600} alt="bastar"></img>
          </div>
        </div>
        {!isMobile && (
          <div className="spapaket-innehåll">
            <div>
              <h2>I alla paket ingår:</h2>
              <ul className="innehållslista">
                <li>
                  Mat: Vår och sommar bjuder vi på örtmarinerad kycklingfile,
                  färskpotatissallad med soltorkade tomater. <br></br>Höst och
                  vinter serveras en värmande soppa goda tillbehör.
                </li>
                <li>Kaffe/Te och dessert.</li>
                <li>Lån av badrock och handduk</li>
                <li>Schampo och balsam</li>
              </ul>
              <p>
                Till maten finns dryck att köpa, välj med eller utan alkohol.
              </p>
              <ul className="innehållslista">
                <li>bubbel</li>
                <li>rött och vitt vin</li>
                <li>öl</li>
                <li>läsk och mineralvatten</li>
              </ul>
              <p>
                I vår snackbar finns även lite annat tilltugg som nötter,
                choklad, godis med mera.
              </p>
            </div>

            <img src={"/assets/sommarmat.jpg"} alt="sommarmat"></img>
          </div>
        )}

        <div className="intro-spapaket">
          <img
            src={"/assets/skål-liggande.jpg"}
            width={600}
            alt="spabild"
          ></img>
          <div className="bg-dark-round">
            <p className="fat">Ordningsregler</p>
            <p>
              Det är inte tillåtet att ta med sig egen mat eller alkohol till
              Spaladan.
            </p>
            <p>
              Åldersgräns 18 år, barn från 8 år är välkomna i vuxens sällskap.
            </p>
            <p>Inga husdjur är tillåtna i Spaladans lokaler.</p>
            <p>Rökning är endast tillåten utanför Spaladan på anvisad plats.</p>
          </div>
        </div>
        <div className="button-center">
          <a href="https://www.timecenter.se/spaladan/" target="_blank">
            <button>Gå till bokningssida för Loftahammar </button>
          </a>
        </div>
      </div>
    </>
  );
}
